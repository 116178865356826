import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base/base.service';
import { HttpClient } from '@angular/common/http';
import {
  AddinButton,
  AddinContextMenu,
  AddinList,
  AddinRecordMeta,
  AddinVisibilityManagable,
  EnabledAddin,
  MatterAddinPostPayload,
} from '@app/features/matter-addin/models';
import { catchError, map, switchMap } from 'rxjs/operators';

import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { IMatterCore } from '@app/features/+matter-list/models';
import { AuthService } from '@app/core/services';
import { ToastrService } from 'ngx-toastr';
import { AppApiService } from '@app/core/api';
import * as matterAddinActions from '@app/features/matter-addin/store/action';
import * as precedentActions from '@app/features/+precedent/store/actions';
import { select, Store } from '@ngrx/store';
import { defaultBrowser } from '@app/features/matter-addin/constants/matter-addin.constants';
import { ICostRecoverySummary } from '@app/features/+cost-recovery-ledger/models';
import { selectPageNumber } from '@app/features/matter-addin/store/selector/matter-addin.selector';
import { ITimeFeeSummary } from '@app/features/+time-fee-ledger/models';
import { BillingMode } from '@app/features/accounting/constants';
import { IAnticipatedDisbursementListEntry } from '@app/features/+anticipated-payment-ledger/models';
import { IOfficeLedgerMatterItem } from '@app/features/+payments-debtors/models';
import { ITrustLedgerItem } from '@app/features/+trust/models';
import { IInvestmentTransactionLedger } from '@app/features/+trust-investment-ledger/models';
import { skipHeaders } from '@app/core/interceptors';
import { selectCurrentMatterCore } from '@app/core/store';
import {
  AutomationActionType,
  IAutomationOptions,
  ICreateNewDocumentTicketParams,
  IOpenPrecedentTicketParams,
} from '@app/shared/models';
import { DocumentAutomationService, OfflineLauncherService } from '@app/shared/services';
import { getCustomizedPrecedentProps, ICustomPrecedent, IPrecedent, UserAction } from '@app/features/+precedent/models';
import { intersectionBetweenArraysByProp, isEmptyValue } from '@server/modules/shared/functions/common-util.functions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MatterAddinService extends BaseService {
  private readonly pageContextMapping: { [key: string]: number } = {
    DocList: 1,
    TimeFeeLedger: 4,
    OfficeAccounting: 5,
    AnticipatedPaymentLedger: 6,
    CostRecoveryLedger: 7,
    TrustLedger: 8,
    TrustInvestmentLedger: 9,
  };

  private _contextMenuSub: Subscription;

  constructor(
    private _http: HttpClient,
    private _authSvc: AuthService,
    private _toastrSvc: ToastrService,
    private _store: Store<any>,
    private _appApiSvc: AppApiService,
    private _offlineLauncherSvc: OfflineLauncherService,
    private _documentAutomationSvc: DocumentAutomationService,
    private _translateSvc: TranslateService
  ) {
    super();
  }

  loadAddins(): Observable<any> {
    const headers = skipHeaders();
    return this._http
      .get<AddinList>(this.listAddinPath, { headers })
      .pipe(
        map((res) => {
          const addins = [];
          res.APIList?.forEach((apiListEntry) => {
            addins.push(apiListEntry.APIAddIn);
          });
          return addins;
        })
      );
  }

  getEnabledAddins(): Observable<string[]> {
    const url = this.urlJoin(this.apiPath, '/api/v1/addins');
    return this._http.get<{ addInName: string }[]>(url).pipe(
      map((res) => {
        const enabledAddinNames: string[] = [];
        res?.forEach((enabledAddin) => {
          if (enabledAddin.addInName !== '') {
            enabledAddinNames.push(enabledAddin.addInName);
          }
        });
        return enabledAddinNames;
      }),
      catchError((error) => of([]))
    );
  }

  // duplicate from MatterTypeService as only item required for matter addin
  // including this here removes need to import entire matter type module (as it is lazy loaded)
  getMatterTypeAncestors(matterTypeId = ''): Observable<string[]> {
    const url = this.urlJoin(this.schemaPath, '/api/mattertypes/query', `?id=${matterTypeId}`);
    return this._http.get<any>(url).pipe(
      map((response) => {
        const matterType = response.data[0];
        const paths = `${matterType.path}${matterTypeId}`;
        return paths.split('/').filter((x) => !isEmptyValue(x));
      }),
      catchError((error) => of([]))
    );
  }

  formatAddinsContextMenu(addinsContextMenu, agGridParams) {
    const contextMenu = [];
    addinsContextMenu?.forEach((menuItem) => {
      if (menuItem === 'separator') {
        contextMenu.push(menuItem);
      } else {
        contextMenu.push({
          name: menuItem.Title,
          action: () => this.addinContextMenuClicked(menuItem, agGridParams.node.data),
        });
      }
    });
    return contextMenu;
  }

  shouldShowAddinButtons(matterCore: IMatterCore, paths: string[], button: AddinButton, currentPage: number): boolean {
    if (!this.filterVisibilityLevel1(matterCore, paths, button)) {
      return false;
    }
    if (button.PageNumber && button.PageNumber.length > 0 && !!!button.PageNumber?.find((p) => p === currentPage)) {
      return false;
    }
    return true;
  }

  shouldShowAddinContextMenu(
    matterCore: IMatterCore,
    paths: string[],
    menu: AddinContextMenu,
    currentPage: number
  ): boolean {
    if (!this.filterVisibilityLevel1(matterCore, paths, menu)) {
      return false;
    }
    if (this.pageContextMapping[menu.Context] !== currentPage) {
      return false;
    }
    return true;
  }

  openAddin(addinItem: any, transactions: any = [], matterCore: IMatterCore): void {
    const { Name, MessageBus } = addinItem;
    const URL = addinItem.URL.replace(' ', '');
    let { Browser } = addinItem;
    const payload = this.prepareAddinActionPayload(matterCore, transactions);

    // if browser is not defined use default browser settings from Desktop
    Browser = { ...defaultBrowser, ...Browser };

    this._http
      .post<string>(this.urlJoin(this.leapAddinPath, !!MessageBus ? '/api/v1/addins' : '/api/v1/addins/forward'), {
        addinUrl: URL,
        base64EncodedData: btoa(JSON.stringify(payload)),
      })
      .subscribe(
        (res) => {
          // only if meta type is defined and not system will we open in a new tab
          if (isEmptyValue(Browser.Type) || Browser.Type === 'system') {
            this._store.dispatch(
              new matterAddinActions.OpenMatterAddin({
                title: Name,
                url: !!MessageBus ? `${URL}&requestId=${res}` : res,
                meta: Browser,
                options: {
                  size: 'auto',
                },
              })
            );
            this._appApiSvc.viewMatterAddin({ title: Name });
          } else {
            window.open(res);
          }
        },
        (err) => {
          this._toastrSvc.warning(
            this._translateSvc.instant('Global.Addin.Open.NotAvailable.Message'),
            this._translateSvc.instant('Global.Addin.Open.NotAvailable.Title'), {
            closeButton: true,
          });
        }
      );
  }

  openAddinRecord(addinItem: any): boolean {
    if (!addinItem || !addinItem.ExternalURL) {
      return false;
    }

    let meta: AddinRecordMeta = {
      OpenType: '0',
      ShowControls: false,
      MinWidth: 0,
      MinHeight: 0,
      Height: 0,
      Width: 0,
    };
    try {
      meta = JSON.parse(addinItem.ExternalJSON) as AddinRecordMeta;
      // if no height and width provided ensure defaults are set
      // values taken from desktop
      if (!meta.Height) {
        meta.Height = 650;
      }
      if (!meta.Width) {
        meta.Width = 1024;
      }
    } catch (e) {}

    const url = this.buildQueryString(addinItem.ExternalURL, { authtoken: this._authSvc.token });

    if (meta.OpenType === '1') {
      this._store.dispatch(
        new matterAddinActions.OpenMatterAddin({
          title: meta.WindowTitle,
          url,
          meta,
          options: {
            size: 'auto',
          },
        })
      );
      this._appApiSvc.viewMatterAddin({ title: meta.WindowTitle });
    } else {
      window.open(url, '_blank');
    }

    return true;
  }

  openAddinTimeEntry(timeEntryAddin: any, matterCore: IMatterCore) {
    if (!timeEntryAddin || !timeEntryAddin.TimeEntryConfig) {
      this._appApiSvc.newNonAddinTimeEntry();
    }

    const config = timeEntryAddin.TimeEntryConfig.Configuration[0];
    const meta: AddinRecordMeta = config.Browser;
    const payload = this.prepareAddinActionPayload(matterCore, []);
    // must inform addin that this is a web call
    const url = config.URL.indexOf('?') > -1 ? `${config.URL}&isWeb=true` : `${config.URL}?isWeb=true`;

    this._http
      .post<string>(
        this.urlJoin(this.leapAddinPath, !!timeEntryAddin.MessageBus ? '/api/v1/addins' : '/api/v1/addins/forward'),
        {
          addinUrl: url,
          base64EncodedData: btoa(JSON.stringify(payload)),
        }
      )
      .subscribe(
        (res) => {
          // only if meta type is defined and not system will we open in a new tab
          this._store.dispatch(
            new matterAddinActions.OpenMatterAddin({
              title: 'Time Entry',
              url: !!timeEntryAddin.MessageBus ? `${url}&requestId=${res}` : res,
              meta,
              options: {
                size: 'auto',
              },
            })
          );
          // New Billable Items is current name for Legal Aid Time Entries
          this._appApiSvc.viewMatterAddin({ title: 'New Billable Items' });
        },
        (err) => {
          this._appApiSvc.newNonAddinTimeEntry();
        }
      );
  }

  createPrecedentDocument(precedent: IPrecedent) {
    const params: IOpenPrecedentTicketParams = {
      action: AutomationActionType.New,
      precedentId: precedent.id,
      ext: precedent.documentType,
    };
    return this._offlineLauncherSvc
      .createOpenPrecedentTicket(params, {
        navigateClear: true,
      })
      .pipe(
        map((ticketId) => new precedentActions.OpenPrecedentSuccess(ticketId)),
        catchError((e) => of(new precedentActions.OpenPrecedentFailure(e)))
      );
  }

  createOnlinePrecedentDocument(matterId: string, precedent: IPrecedent) {
    const params: IAutomationOptions = {
      action: AutomationActionType.New,
      matterId,
      folderId: null,
      precedentInfo: {
        precedent,
      },
    };

    const customizedProps = getCustomizedPrecedentProps(UserAction.CreatePrecedent, precedent);

    return this._documentAutomationSvc
      .createFromOrEditPrecedentOnline(params, customizedProps)
      .pipe(switchMap(() => [new precedentActions.OpenPrecedentSuccess(null)]));
  }

  createNewDocument(
    matterId: string,
    containerId: string,
    tableId: string,
    order: number,
    instance: string,
    resultDocumentId: string
  ) {
    const params: ICreateNewDocumentTicketParams = {
      matterId,
      precedentId: containerId,
      defaultTableId: tableId,
      defaultTableOrder: order.toString(),
      defaultPersonInstance: instance,
    };
    return this._offlineLauncherSvc.createNewDocumentTicket(
      params,
      {
        navigateClear: true,
      },
      resultDocumentId
    );
  }

  createOnlineDocument(
    matterId: string,
    tableId: string,
    order: number,
    instance: string,
    containerId: string,
    container: ICustomPrecedent
  ) {
    const params: IAutomationOptions = {
      action: AutomationActionType.New,
      folderId: null,
      matterId,
      isOfficeOnline: true,
      containerInfo: {
        defaultTableId: tableId,
        defaultTableOrder: order.toString(),
        defaultPersonInstance: instance,
        isCustomPrecedent: container.isCustomized,
        customPrecedent: container,
        precedentId: containerId,
      },
    };
    return this._documentAutomationSvc.newContainerOnline(params);
  }

  private prepareAddinActionPayload(matterCore: IMatterCore, transactions: any): MatterAddinPostPayload {
    const payload: MatterAddinPostPayload = {
      authtoken: this._authSvc.token,
      matterContext: {
        matterId: matterCore.__id,
        matterTypeId: matterCore.matterTypeId,
      },
    };

    if (transactions.length > 0) {
      payload['matterContext']['transactions'] = transactions;
    }

    return payload;
  }

  private filterVisibilityLevel1(matterCore: IMatterCore, paths: string[], obj: AddinVisibilityManagable): boolean {
    if (
      obj.MatterType &&
      obj.MatterType.length > 0 &&
      intersectionBetweenArraysByProp(obj.MatterType, paths, (a: any, b: any) => a.toString() === b).length === 0
    ) {
      return false;
    }

    if (obj.BillingMode && obj.BillingMode.length > 0) {
      // if billing mode supplied must be checked for visibility
      // against matter billing mode
      if (matterCore && matterCore.accounting && matterCore.accounting.billingMode === null) {
        // matter billing mode is private so anything with specified billing mode
        // should not be shown unless specified billing mode === 0
        if (!obj.BillingMode.includes(0)) {
          return false;
        }
      } else {
        if (
          !!!obj.BillingMode?.find(
            (m) => matterCore && matterCore.accounting && m === matterCore.accounting.billingMode
          )
        ) {
          // matter billing mode is not private and must match specified billing mode
          // otherwise not shown
          return false;
        }
      }
    }

    if (obj.State && obj.State.length > 0 && !!!obj.State?.find((s) => matterCore && s === matterCore.state)) {
      return false;
    }
    return true;
  }

  private addinContextMenuClicked(menuItem: AddinContextMenu, itemScope: any) {
    // note: the `itemScope` parameter is a context menu's $itemScope object. The VM object
    // behind it can be access via `itemScope.summary` if the variable name in ng-repeat is
    // `summary`. While we can code it differently to make it generic, it's not worth the
    // hassle as too many view files will have to be updated.
    const pageNumber$ = this._store.pipe(select(selectPageNumber));
    const matterCore$ = this._store.pipe(select(selectCurrentMatterCore));

    this._contextMenuSub = combineLatest([pageNumber$, matterCore$]).subscribe((data) => {
      const [pageNumber, matterCore] = data;
      let transactions: any = [];
      switch (pageNumber) {
        case 1:
          break;
        case 4: {
          const record = itemScope as ITimeFeeSummary;
          const Mode: typeof BillingMode = BillingMode;
          const billingStatus = Mode?.find((m) => m.mode === record.BillingMode).name;
          // transcribe 4D method MatterAddIn_JSONTimeFee
          transactions = [
            {
              timeFeeId: record.FeeGUID,
              timeFeeStaffId: record.WorkDoneByStaffGUID,
              billingStatus,
              billingId: record.BillingMode.toString(),
            },
          ];
          break;
        }
        case 6: {
          const record = itemScope as IAnticipatedDisbursementListEntry;
          // transcribe 4D method MatterAddIn_JSONAntPayments
          transactions = [
            {
              anticipatedPaymentId: record.AnticipatedDisbursementsGUID,
              billingStatusId: record.BillingMode,
              paymentStatusId: record.BillingMode,
            },
          ];
          break;
        }
        case 5: {
          const record = itemScope as IOfficeLedgerMatterItem;
          let transactionType = '';
          let invoiceStatus = 0;
          switch (record.TransactionType) {
            case 0: {
              transactionType = 'History View Only Item';
              break;
            }
            case 1: {
              transactionType = 'Office Payment';
              break;
            }
            case 2: {
              transactionType = 'Disbursement Journal';
              break;
            }
            case 3: {
              transactionType = 'Office Receipt';
              break;
            }
            case 10: {
              transactionType = 'Office Invoice';
              invoiceStatus = record.InvoiceStatus;
              break;
            }
            case 11: {
              transactionType = 'Invoice Adjustment';
              break;
            }
            case 12: {
              transactionType = 'Credit Refund';
              break;
            }
            case 13: {
              transactionType = 'Credit Journal';
              break;
            }
            case 20: {
              transactionType = 'Payment Request';
              break;
            }
          }
          // transcribe 4D method MatterAddIn_JSONOfficeAcc
          transactions = [
            {
              transactionType,
              transactionId: record.TransactionHeaderGUID,
            },
          ];
          if (record.TransactionType === 10) {
            transactions[0].invoiceStatus = invoiceStatus;
          }
          break;
        }
        case 7: {
          const record = itemScope as ICostRecoverySummary;
          // transcribe 4D method MatterAddIn_JSONCostRec
          transactions = [
            {
              costRecoveryId: record.CostRecoveryGUID,
              billingStatusId: record.BillingMode.toString(),
            },
          ];
          break;
        }
        case 8: {
          const record = itemScope as ITrustLedgerItem;
          let transactionType = '';
          switch (record.TransactionType) {
            case 1: {
              transactionType = 'Trust Payment';
              break;
            }
            case 2: {
              transactionType = 'Trust Journal';
              break;
            }
            case 3: {
              transactionType = 'Trust Receipt';
              break;
            }
            case -999: {
              transactionType = 'Ledger history item';
              break;
            }
            case -888: {
              transactionType = 'Payment Request';
              break;
            }
            case -123: {
              transactionType = 'Anticipated Deposits';
              break;
            }
          }
          // transcribe 4D method MatterAddIn_JSONTrustFunds
          transactions = [
            {
              transactionType,
              transactionId: record.TransactionHeaderGUID,
            },
          ];
          break;
        }
        case 9: {
          const record = itemScope as IInvestmentTransactionLedger;
          // transcribe 4D method MatterAddIn_JSONControlMoney
          transactions = [
            {
              transactionId: record.InvestTranGUID,
            },
          ];
          break;
        }
      }
      this.openAddin(menuItem, transactions, matterCore);
    });
    this._contextMenuSub.unsubscribe();
  }

  private buildQueryString(url: string, params: any): string {
    if (url.indexOf('?') > 0) {
      Object.keys(params).forEach((k) => {
        url = `${url}&${k}=${encodeURIComponent(params[k])}`;
      });
    } else {
      const keyCollection = Object.keys(params);
      if (keyCollection.length > 0) {
        url = `${url}?${keyCollection[0]}=${encodeURIComponent(params[keyCollection[0]])}`;
      }
      for (let i = 1; i < keyCollection.length; i++) {
        url = `${url}&${keyCollection[i]}=${encodeURIComponent(params[keyCollection[i]])} `;
      }
    }
    // must ensure isWeb is passed with all URLs
    return url.indexOf('?') > -1 ? `${url}&isWeb=true` : `${url}?isWeb=true`;
  }
}
