<div class="x-form-wrapper x-accounting-form">
  <sc-row *ngIf="!!receiptForm" [formGroup]="receiptForm">
    <sc-col class="x-max-width-320">
      <sc-form-row>
        <sc-col class="form-group">
          <label>Bank Account</label>
          <ng-select
            class="focusOnLoad"
            placeholder=""
            [items]="bankAccounts"
            bindLabel="NameFileAs"
            bindValue="BankAccountGUID"
            [clearable]="false"
            [searchable]="false"
            formControlName="BankAccountGUID"
          ></ng-select>
        </sc-col>
      </sc-form-row>

      <sc-form-row>
        <sc-col class="form-group">
          <label>Received From</label>
          <div class="x-textarea-with-button">
            <sc-textarea
              [inputClass]="{ 'x-is-invalid': validationErrors.hasRecievedFromError }"
              [rowNumber]="5"
              [focus]="false"
              formControlName="ReceivedFrom"
            >
            </sc-textarea>
            <div class="x-button-top-right">
              <sc-btn
                [tabindex]="-1"
                [btnSize]="'sm'"
                [btnType]="'default'"
                tooltip="{{ 'Select Card' }}"
                container="body"
                data-placement="right"
                (btnClicked)="selectCard()"
              >
                <sc-icon iconName="browse"></sc-icon>
              </sc-btn>
            </div>
          </div>
        </sc-col>
      </sc-form-row>
    </sc-col>
    <sc-col class="col-3">
      <sc-form-row>
        <sc-col class="form-group">
          <sc-inflow-payment-type
            [paymentTypeList]="paymentTypeList"
            [paymentTypeForm]="receiptForm.controls['PaymentType']"
            [validationErrors]="validationErrors.hasPaymentTypeError"
          >
          </sc-inflow-payment-type>
        </sc-col>
      </sc-form-row>
    </sc-col>
    <sc-col>
      <sc-form-row>
        <sc-col class="form-group">
          <sc-receipt-number
            *ngIf="receiptForm.controls['AutoTransactionNumber']"
            [bankAccount]="bankAccount"
            [transactionNumberForm]="receiptForm.controls['AutoTransactionNumber']"
            [transactionNumberLabel]="'Trust.Receipt.TransactionNumber'"
            [validationErrors]="validationErrors.hasTransactionNumberError"
          ></sc-receipt-number>
        </sc-col>
      </sc-form-row>
      <sc-form-row>
        <sc-col class="form-group">
          <sc-date
            [styleClass]="'x-max-width-180'"
            formControlName="TransactionDate"
            [label]="'Trust.InvestmentTransaction.TransactionDate.Label'"
            [inputClass]="{ 'x-is-invalid': validationErrors.hasTransactionDateError }"
          ></sc-date>
        </sc-col>
        <sc-col class="form-group">
          <sc-date
            [styleClass]="'x-max-width-180'"
            formControlName="EntryDate"
            [label]="'Trust.InvestmentTransaction.EntryDate.Label'"
          ></sc-date>
        </sc-col>
      </sc-form-row>
    </sc-col>
  </sc-row>
</div>
