import { AuthService } from './auth/auth.service';
import { BrandService } from './brand/brand.service';
import { CacheService } from './cache/cache.service';
import { ConfigService } from './config/config.service';
import { DexieService } from './dexie/dexie.service';
import { FeatureControlService } from './feature-control/feature-control.service';
import { FormStorageService } from './storage/form-storage.service';
import { LogService } from './log/log.service';
import { PlatformService } from './platform/platform.service';
import { PubnubService } from './pubnub/pubnub.service';
import { StartupService } from './startup/startup.service';
import { StaffService } from './staff/staff.service';
import { FirmService } from './firm/firm.service';
import { PermissionsService } from './permissions/permissions.service';
import { ScToastrService } from '@app/core/services/toastr/sc-toastr.service';
import { ScHotKeyService } from './hotkey/hot-key.service';
import { UserPreferencesService } from './preferences/user-preferences.service';
import { EmailService } from './email/email.service';
import { NotificationApiService, NotificationStorageService } from '@app/features/+notification/services';
import { MatterListStorageService } from '@app/features/+matter-list/services/storage/matter-list-storage.service';
import { AnalyticsService } from './analytics/analytics.service';
import { EventBusService } from './event-bus/event-bus.service';
import { CardListStorageService } from '@app/features/+card/services/card-list-storage.service';
import { NamedListsStorageService } from '@app/features/+card/services/named-lists-storage.service';
import { PersonListStorageService } from '@app/features/+person/services/person-list-storage.service';
import { FeatureFlagService } from './feature-flag/feature-flag.service';

export * from './auth/auth.service';
export * from './auth/auth-agent.config';
export * from './brand/brand.service';
export * from './cache/cache.service';
export * from './config/config.service';
export * from './dexie/dexie.service';
export * from './feature-control/feature-control.service';
export * from './log/log.service';
export * from './platform/platform.service';
export * from './pubnub/pubnub.service';
export * from './startup/startup.service';
export * from './storage/app-storage.service';
export * from './staff/staff.service';
export * from './firm/firm.service';
export * from './permissions/permissions.service';
export * from './storage/form-storage.service';
export * from './toastr/sc-toastr.service';
export * from './hotkey/hot-key.service';
export * from './preferences/user-preferences.service';
export * from './email/email.service';
export * from './analytics/analytics.service';
export * from './event-bus/event-bus.service';
export * from './feature-flag/feature-flag.service';

export const APP_SERVICES = [
  AuthService,
  BrandService,
  ConfigService,
  CacheService,
  DexieService,
  FeatureControlService,
  LogService,
  PlatformService,
  PubnubService,
  StartupService,
  StaffService,
  FirmService,
  PermissionsService,
  FormStorageService,
  ScToastrService,
  ScHotKeyService,
  UserPreferencesService,
  EmailService,
  NotificationApiService,
  NotificationStorageService,
  MatterListStorageService,
  CardListStorageService,
  NamedListsStorageService,
  PersonListStorageService,
  EventBusService,
  AnalyticsService,
  FeatureFlagService
];
