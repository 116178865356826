import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { catchError, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as cardModalActions from '../actions/card-modal';
import * as matterCardActions from '../actions/matter-card';
import * as currentMatterActions from '@app/features/+matter-details/store/actions/current-matter';
import { selectCurrentDetailEntry, selectDetailEntries } from '@app/features/+matter-details/store';
import { IMatterCard, IMatterDetailEntry } from '@app/shared/models';
import { MatterDetailsService } from '@app/features/+matter-details/services';
import { selectCurrentMatterId } from '@app/core/store';
import { selectCardModalFormValue, selectCardModalIsFormValueModified } from '@app/features/+card/store/selectors';
import { DebtorClass } from '@app/features/+matter-details/constants';
import { isMatterCard } from '@app/features/+matter-details/functions';
import { CardDetailsService } from '@app/features/+card/services';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ECardType } from '../../models';
import { sortAscBy } from '@server/modules/shared/functions/common-util.functions';
import { AuthService } from '@app/core/services';

@Injectable()
export class MatterCardEffect {

  loadMatterCard$: any = createEffect(() => this.actions$.pipe(
    ofType<matterCardActions.LoadMatterCard>(matterCardActions.MatterCardActionTypes.LOAD_MATTER_CARD),
    withLatestFrom(this.store.pipe(select(selectCurrentDetailEntry))),
    tap(([action, detailEntry]: [matterCardActions.LoadMatterCard, IMatterCard]) => {
      const cardId = detailEntry ? detailEntry.cardId : null;
      if (cardId) {
        this.store.dispatch(new cardModalActions.LoadCardDetailsStart({ id: cardId, reset: true, isMatterCard: true }));
      } else {
        this.store.dispatch(new cardModalActions.StartNewCard({ type: action.payload.cardType, isMatterCard: true }));
      }
    })
  ), { dispatch: false });


  saveMatterCard$: any = createEffect(() => this.actions$.pipe(
    ofType<matterCardActions.SaveMatterCard>(matterCardActions.MatterCardActionTypes.SAVE_MATTER_CARD),
    withLatestFrom(
      this.store.pipe(select(selectCurrentMatterId)),
      this.store.pipe(select(selectCurrentDetailEntry)),
      this.store.pipe(select(selectCardModalFormValue)),
      this.store.pipe(select(selectCardModalIsFormValueModified)),
      of(this.authSvc.decodedToken?.userId),
      (
        action: matterCardActions.SaveMatterCard,
        matterId: string,
        matterCard,
        formValue: any,
        isFormValueModified: boolean,
        userId: string
      ) => [
        action.payload.isNewCard,
        action.payload.isNewMatterCard,
        matterId,
        matterCard,
        formValue,
        isFormValueModified,
        userId
      ]
    ),
    switchMap(([isNewCard, isNewMatterCard, matterId, matterCard, formValue, isFormValueModified, userId]) => {
      // we only trigger the api save call when we are saving a new card or we are saving a modified cardDetails
      if (isNewCard || isFormValueModified) {
        const properFormValue = this.cardDetailsSvc.formatFormValue(formValue, userId);
        let card = { ...matterCard };

        if (properFormValue.type !== ECardType.People) {
          card = {
            ...card,
            reference: properFormValue.matterCardReference,
          };
        }

        return this.matterDetailsSvc.saveMatterCard(matterId, card, properFormValue, isNewMatterCard).pipe(
          mergeMap(() => [
            new cardModalActions.SaveSucceed({
              message: this.translateSvc.instant('Matter.Card.Create.Success.Message'),
            }),
          ]),
          catchError((error) =>
            of(
              new cardModalActions.SaveFail({
                ...error,
                message: this.translateSvc.instant('Matter.Card.Create.Error.Message'),
              })
            )
          )
        );
      } else {
        // if the cardDetails is not modified, we close the card modal
        this.store.dispatch(new cardModalActions.CloseModal(null));
        return [];
      }
    })
  ));

  /* eslint-disable  */
  
  setDebtorCard$: any = createEffect(() => this.actions$.pipe(
    ofType<matterCardActions.SetDebtorCard>(matterCardActions.MatterCardActionTypes.SET_DEBTOR_CARD),
    withLatestFrom(this.store.pipe(select(selectCurrentMatterId)), this.store.pipe(select(selectCurrentDetailEntry))),
    switchMap(([, matterId, matterCard]: [matterCardActions.SetDebtorCard, string, IMatterCard]) => {
      return this.matterDetailsSvc.setDebtorCard(matterId, matterCard).pipe(
        mergeMap((savedMatterCard: IMatterCard) => {
          return [
            new matterCardActions.SetDebtorCardSucceed({
              savedMatterCard,
              matterCard,
              formValue: null,
            }),
          ];
        }),
        catchError((error) =>
          of(
            new cardModalActions.SaveFail({
              ...error,
              message: this.translateSvc.instant('Matter.Card.Debtor.Update.Error.Message'),
            })
          )
        )
      );
    })
  ));

  /* eslint-disable  */
  
  addDebtorCard$: any = createEffect(() => this.actions$.pipe(
    ofType<matterCardActions.AddDebtorCard>(matterCardActions.MatterCardActionTypes.ADD_DEBTOR_CARD),
    withLatestFrom(
      this.store.pipe(select(selectCurrentMatterId)),
      this.store.pipe(select(selectCurrentDetailEntry)),
      this.store.pipe(select(selectCardModalFormValue)),
      this.store.pipe(select(selectCardModalIsFormValueModified)),
      of(this.authSvc.decodedToken?.userId)
    ),
    switchMap((data: any) => {
      const [matterId, matterDetail, formValue, isFormValueModified, userId] = data;
      if (isFormValueModified) {
        let matterCard = matterDetail as IMatterCard;
        const properFormValue = this.cardDetailsSvc.formatFormValue(formValue, userId);
        return this.matterDetailsSvc.setDebtorCard(matterId, matterCard, properFormValue).pipe(
          mergeMap((savedMatterCard: IMatterCard) => {
            if (properFormValue.type !== ECardType.People) {
              matterCard = {
                ...matterCard,
                reference: properFormValue.matterCardReference,
              };
            }
            return [
              new matterCardActions.SetDebtorCardSucceed({
                savedMatterCard,
                matterCard,
                formValue: properFormValue,
              }),
            ];
          }),
          catchError((error) => [
              new cardModalActions.SaveFail({
                ...error,
                message: this.translateSvc.instant('Matter.Card.Debtor.Add.Error.Message'),
              })]            
          )
        );
      } else {
        // if the cardDetails is not modified, we close the card modal
        this.store.dispatch(new cardModalActions.CloseModal(null));
        return [];
      }
    })
  ));
  /* eslint-enable  */

  /* eslint-disable  */
  
  setDebtorCardSucceed$: any = createEffect(() => this.actions$.pipe(
    ofType(matterCardActions.MatterCardActionTypes.SET_DEBTOR_CARD_SUCCEED),
    withLatestFrom(this.store.pipe(select(selectDetailEntries))),
    switchMap(([action, detailEntries]: [matterCardActions.SetDebtorCardSucceed, IMatterDetailEntry[]]) => {
      const { savedMatterCard, matterCard, formValue, hideMessage } = action.payload;
      const oldDebtorCard = detailEntries?.find((d) => d.__className === DebtorClass);
      const oldMatterCardId = oldDebtorCard ? oldDebtorCard.__id : undefined;
      let newDetailEntries: IMatterDetailEntry[] = !oldMatterCardId
        ? sortAscBy([...detailEntries, savedMatterCard], (c) => c.__displayOrder)
        : [...detailEntries].map((d) => {
            if (isMatterCard(d)) {
              const card = { ...d } as IMatterCard;
              card.isDebtor = !formValue && d.__id === matterCard.__id;
              card.context = {
                ...d.context,
                displayDebtorForAccounting: !d.context.hidden && card.isDebtor,
              };
            } else {
              return d;
            }
          });

      newDetailEntries = detailEntries.map((detailEntry: IMatterDetailEntry) => {
        return isMatterCard(detailEntry) &&
          detailEntry.tableId === savedMatterCard.tableId &&
          detailEntry.__displayOrder === savedMatterCard.__displayOrder
          ? savedMatterCard
          : detailEntry;
      });

      if (hideMessage) {
        return [new currentMatterActions.SetDetailEntries({ detailEntries: newDetailEntries })];
      } else {
        const message = this.translateSvc.instant('Matter.Card.Debtor.Update.Success.Message');
        return [
          new currentMatterActions.SetDetailEntries({ detailEntries: newDetailEntries }),
          new cardModalActions.SaveSucceed({ message }),
        ];
      }
    })
  ));

  /* eslint-enable */

  removeMatterCardStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<matterCardActions.RemoveMatterCardStart>(matterCardActions.MatterCardActionTypes.REMOVE_MATTER_CARD_START),
    withLatestFrom(this.store.pipe(select(selectCurrentMatterId)), this.store.pipe(select(selectDetailEntries))),
    switchMap((data) => {
      const [action, currentMatterId] = data;
      const { matterCardId, displayOrder } = action.payload;

      return this.matterDetailsSvc.deleteMatterCard(currentMatterId, matterCardId, displayOrder).pipe(
        mergeMap(() => [new matterCardActions.RemoveMatterCardSuccess(null)]),
        catchError(() => [new matterCardActions.RemoveMatterCardFail(null)])
      );
    })
  ));


  removeMatterCardSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(matterCardActions.MatterCardActionTypes.REMOVE_MATTER_CARD_SUCCESS),
    tap(() => {
      this.toastrSvc.show(this.translateSvc.instant('Matter.Card.Remove.Success.Message'), 'Success', {}, 'success');
    })
  ), { dispatch: false });


  removeMatterCardFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(matterCardActions.MatterCardActionTypes.REMOVE_MATTER_CARD_FAIL),
    tap(() => {
      this.toastrSvc.show(this.translateSvc.instant('Matter.Card.Remove.Error.Message'), 'Error', {}, 'error');
    })
  ), { dispatch: false });


  removeDebtorForAccountingCardStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(matterCardActions.MatterCardActionTypes.REMOVE_DEBTOR_FOR_ACCOUNTING_CARD_START),
    withLatestFrom(
      this.store.pipe(select(selectCurrentMatterId)),
      (action: matterCardActions.RemoveDebtorForAccountingCardStart, currentMatterId: string) => ({
        clientCard: action.payload.clientCard,
        currentMatterId,
      })
    ),
    switchMap((data: { clientCard: IMatterCard; currentMatterId: string }) => this.matterDetailsSvc.setDebtorCard(data.currentMatterId, data.clientCard).pipe(
        mergeMap((savedMatterCard: IMatterCard) => [
            new matterCardActions.SetDebtorCardSucceed({
              savedMatterCard,
              matterCard: data.clientCard,
              formValue: null,
              hideMessage: true,
            }),
            new matterCardActions.RemoveMatterCardSuccess(null),
          ]),
        catchError(() => of(new matterCardActions.RemoveMatterCardFail(null)))
      ))
  ));


  removeDebtorCardStart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<matterCardActions.RemoveDebtorCardStart>(matterCardActions.MatterCardActionTypes.REMOVE_DEBTOR_CARD_START),
    withLatestFrom(this.store.pipe(select(selectCurrentMatterId))),
    switchMap((data) => {
      const [action, currentMatterId] = data;
      const { clientCard, matterCardId, displayOrder } = action.payload;
      return this.matterDetailsSvc.setDebtorCard(currentMatterId, clientCard).pipe(
        mergeMap((savedMatterCard: IMatterCard) => [
            new matterCardActions.SetDebtorCardSucceed({
              savedMatterCard,
              matterCard: clientCard,
              formValue: null,
              hideMessage: true,
            }),
            new matterCardActions.RemoveMatterCardStart({
              matterCardId,
              displayOrder,
            }),
          ]),
        catchError(() => of(new matterCardActions.RemoveMatterCardFail(null)))
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private translateSvc: TranslateService,
    private matterDetailsSvc: MatterDetailsService,
    private cardDetailsSvc: CardDetailsService,
    private toastrSvc: ToastrService,
    private authSvc: AuthService
  ) {}
}
