import { BaseService } from './base/base.service';
import { BaseStorageService } from './base/base-storage.service';
import { BrowserService } from './browser/browser.service';
import { CalcService } from './calc/calc.service';
import { DialogService } from './dialog/dialog.service';
import { DocumentAutomationService } from './document-automation/document-automation.service';
import { OfflineLauncherService } from './document-automation/offline-launcher/offline-launcher.service';
import { OnlineLauncherService } from './document-automation/online-launcher/online-launcher.service';
import { TableMappingService } from './mapping/table-mapping.service';
import { SchemaService } from './schema/schema.service';
import { SpecialFeeService } from '@app/shared/services/special-fee/special-fee.service';
import { SpecialFeeHttpService } from '@app/shared/services/special-fee-http/special-fee-http.service';
import { TextUtilsService } from './text-utils/text-utils.service';
import { UiUtilsService } from './ui/ui-utils.service';
import { EditorService } from '@app/shared/components/editor/editor.service';
import { CanDeactivateTimeEntryGuardService } from '@app/shared/services/guard/can-deactivate-time-entry-guard.service';
import { TimeEntryValidationService } from '@app/shared/services/guard/time-entry-validation.service';
import { TrustMatterBalanceService } from '@app/shared/services/trust-matter-balance/trust-matter-balance.service';
import { PersonUtilsService } from '@app/shared/services/person-utils/person-utils.service';
import { TimerService } from './timer/timer.service';
import { AgGridNavigationService } from './ag-grid-navigation/ag-grid-navigation.service';

export * from './base/base.service';
export * from './base/base-storage.service';
export * from './browser/browser.service';
export * from './calc/calc.service';
export * from './dialog/dialog.service';
export * from './document-automation/document-automation.service';
export * from './document-automation/offline-launcher/offline-launcher.service';
export * from './document-automation/online-launcher/online-launcher.service';
export * from './mapping/table-mapping.service';
export * from './schema/schema.service';
export * from './special-fee/special-fee.service';
export * from './special-fee-http/special-fee-http.service';
export * from './text-utils/text-utils.service';
export * from './trust-matter-balance/trust-matter-balance.service';
export * from './ui/ui-utils.service';
export * from './person-utils/person-utils.service';
export * from './timer/timer.service';
export * from './ag-grid-navigation/ag-grid-navigation.service';

export const sharedBaseServices = [BaseService, BaseStorageService];

export const sharedServices = [
  BrowserService,
  CalcService,
  DialogService,
  DocumentAutomationService,
  EditorService,
  OfflineLauncherService,
  OnlineLauncherService,
  SchemaService,
  TableMappingService,
  TextUtilsService,
  UiUtilsService,
  CanDeactivateTimeEntryGuardService,
  TimeEntryValidationService,
  SpecialFeeService,
  SpecialFeeHttpService,
  TrustMatterBalanceService,
  PersonUtilsService,
  TimerService,
  AgGridNavigationService
];
