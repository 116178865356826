import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EApiPath } from '@app/shared/services';
import { AuthService, LogService } from '@app/core/services';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private logSvc: LogService, private authSvc: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if ('ErrorEvent' in globalThis && error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error

          // if the error is related to unauthorised & app also not able to return valid token
          if (error.status === 401 && !this.authSvc.token) {
            this.authSvc.logout();
            return EMPTY;
          }

          const errorResponseUrl = error.url;
          const isAccountingRequest =
            errorResponseUrl.includes(EApiPath.Accounting) || errorResponseUrl.includes('api/sirius-accounting');

          if (isAccountingRequest) {
            // if it is a accounting request, rethrow the error and let accounting-api.effects to handle the error
            // passing the httpRequest is for handling the accounting warning error
            // and re-triggering the request if the user select ignoring the warning
            return throwError(() => ({ httpError: error, httpRequest: request }));
          }

          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        this.logSvc.error('Http Error Interceptor: ', errorMessage);
        return throwError(() => error); // prevent from silently dying
      })
    );
  }
}
