// core
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PLATFORM_ID, APP_ID, Inject, NgModule, APP_INITIALIZER } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Angulartics2Module } from 'angulartics2';
import { ToastrModule } from 'ngx-toastr';
import { InlineSVGModule } from 'ng-inline-svg-2';
// or if using ag-Grid Enterprise
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import * as SentryBrowser from '@sentry/browser';

// local
import { AccountingModule } from '@app/features/accounting/accounting.module';
import { effects } from '@app/core/store';
import { ProxyRouteCenterComponent, ProxyRouteComponent, ToastComponent } from '@app/shared/components';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import { AppComponent } from './core/app';
import { AppRoutingModule } from './app-routing.module';
import { LogService, StartupService } from '@app/core/services';
import { environment } from '@env/environment';
import { LiveChatModule } from '@app/features/live-chat/live-chat.module';
import { reducers, metaReducers } from '@app/root.reducer';
import { ErrorHandlerConfig, ErrorHandlerHook } from '@app/features/error-handler/interfaces/error-handler.interfaces';
import { DEFAULT_OVERLAY_CONFIG } from '@app/features/error-handler/constants/error-handler.constants';
import { Sentry_DSN } from '@app/core/constants';
import { ERROR_HANDLER_CONFIG, ERROR_HANDLER_LOGGER } from '@app/features/error-handler/error-handler.config';
import { ErrorHandlerModule } from '@app/features/error-handler/error-handler.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const startupServiceFactory = (startupService: StartupService): (() => Promise<void>) => () =>
  startupService.load();

/*
 * Sentry Config
 * */
SentryBrowser.init({
  dsn: Sentry_DSN,
});
const sentryCaptureException: ErrorHandlerHook = (error: any) => {
  if (environment.production && !!error) {
    SentryBrowser.captureException(error.originalError || error);
  }
};

/**
 * Error Handler Config
 * */
const CustomErrorHandlerConfig: ErrorHandlerConfig = {
  overlayConfig: { ...DEFAULT_OVERLAY_CONFIG, panelClass: 'x-error-handler-panel' },
  errorHandlerHooks: [sentryCaptureException],
};

@NgModule({
  declarations: [AppComponent],
  exports: [ProxyRouteComponent, ProxyRouteCenterComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    SharedModule.forRoot(),
    ToastrModule.forRoot({
      toastClass: 'toast',
      toastComponent: ToastComponent,
      timeOut: 5000,
      extendedTimeOut: 3000, // time to close after a user hovers over toast
      progressBar: true,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
      tapToDismiss: true,
    }),
    InlineSVGModule.forRoot({
      baseUrl: '/assets/icons/',
    }),
    AppRoutingModule,
    ErrorHandlerModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: !environment.production,
        strictActionImmutability: !environment.production,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(effects),
    // Note that you must instrument after importing StoreModule
    StoreDevtoolsModule.instrument({
      maxAge: environment.production ? 0 : 25,
      trace: environment.production ? false : true,
    }),
    Angulartics2Module.forRoot(),
    AccountingModule,
    LiveChatModule,
  ],
  providers: [
    { provide: ERROR_HANDLER_CONFIG, useValue: CustomErrorHandlerConfig },
    { provide: ERROR_HANDLER_LOGGER, useClass: LogService },
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [StartupService],
      multi: true,
    },
    BrowserAnimationsModule,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) private platformId: object, @Inject(APP_ID) private appId: string) {
    const isBrowser = isPlatformBrowser(platformId);
    const platform = isBrowser ? 'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId: ${appId}`);
    
    if (isBrowser) {
      ModuleRegistry.registerModules([
        ClientSideRowModelModule,
        ServerSideRowModelModule,
        InfiniteRowModelModule,
        MenuModule,
        RowGroupingModule,
      ]);
    }
  }
}
