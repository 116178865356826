import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EPermissionType } from '../../constants';
import { AuthService } from '../auth/auth.service';
import { LogService } from '../log/log.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { everyMatchCondition } from '../../../../../server/modules/shared/functions/common-util.functions';

@Injectable()
export class PermissionsService {
  private _permissions = [];
  private _lawConnectUser = false;

  constructor(
    private _authSvc: AuthService,
    private _ngxPermissionSvc: NgxPermissionsService,
    private _log: LogService
  ) {
    this._log.init('permissions-sv');
  }

  get isTimeFeeReadOnly() {
    return this._permissions.includes(EPermissionType.FeeRead);
  }

  get shouldRouteToTimeFee() {
    return (
      !(
        this._permissions.includes(EPermissionType.MatterRead) ||
        this._permissions.includes(EPermissionType.MatterWrite)
      ) &&
      (this._permissions.includes(EPermissionType.FeeWrite) || this._permissions.includes(EPermissionType.FeeRead))
    );
  }

  get hasTimeFeeWriteAccess() {
    return (
      // this._permissions.includes(EPermissionType.All) ||  -- Commented till the token is fixed not to have '*'
      this._permissions.includes(EPermissionType.LeapUser) || this._permissions.includes(EPermissionType.FeeWrite)
    );
  }

  get isMatterReadOnly() {
    return this._permissions.includes(EPermissionType.MatterRead);
  }

  get isCardReadOnly() {
    return this._permissions.includes(EPermissionType.CardRead);
  }

  get isContentReadOnly() {
    return this._permissions.includes(EPermissionType.ContentRead);
  }

  get hasMatterWrite() {
    return (
      this._permissions.includes(EPermissionType.LeapUser) || this._permissions.includes(EPermissionType.MatterWrite)
    );
  }

  get isLawConnectUser() {
    return this._lawConnectUser;
  }

  get hasCardEditPermissions() {
    return everyMatchCondition(
      [this._permissions.includes(EPermissionType.CardWrite), this._permissions.includes(EPermissionType.MatterWrite)],
      Boolean
    );
  }

  get hasReadPermissions(): boolean {
    return everyMatchCondition(
      [this._permissions.includes(EPermissionType.MatterRead), this._permissions.includes(EPermissionType.CardRead)],
      Boolean
    );
  }

  setupPermissions() {
    const token = this._authSvc.decodedToken;
    if (!token) {
      this._log.error('token is null, cannot setupPermissions');
      return;
    }
    const userType = token.userType;
    const scope = token.scope;
    // const userType = 'LawConnect';
    // const scope = `matter:8678a794-429f-44af-92ac-f3691d01198f:r, timefee:*:w, card:*:r, content:*:r`;
    const permissionArray = scope.split(',');
    const permissionsArrayValueInString = permissionArray.map((permission) => {
      const access = permission.trim().split(':', 3);
      const [permissionType, ...tail] = access;
      switch (permissionType) {
        case 'profile':
          switch (access[2]) {
            default:
              return '';
          }
        case 'SCHEMA':
          switch (access[2]) {
            default:
              return '';
          }
        case 'matter':
          switch (access[2]) {
            case 'r':
              return EPermissionType.MatterRead;
            default:
              return EPermissionType.MatterWrite;
          }
        case 'card':
          switch (access[2]) {
            case 'r':
              return EPermissionType.CardRead;
            default:
              return EPermissionType.CardWrite;
          }
        case 'content':
          switch (access[2]) {
            case 'r':
              return EPermissionType.ContentRead;
            default:
              return EPermissionType.ContentWrite;
          }
        case 'timefee':
          switch (access[2]) {
            case 'r':
              return EPermissionType.FeeRead;
            default:
              return EPermissionType.FeeWrite;
          }
        default:
          return EPermissionType.All;
      }
    });
    const tokenPermissions = [
      ...permissionsArrayValueInString,
      userType === 'LawConnect' ? EPermissionType.LawConnectUser : EPermissionType.LeapUser,
    ].filter(Boolean);
    this._lawConnectUser = userType === 'LawConnect';
    console.log('In PermissionsService, loading permissions', tokenPermissions);
    this._ngxPermissionSvc.loadPermissions(tokenPermissions);

    this._ngxPermissionSvc.permissions$.pipe(take(1)).subscribe((x) => {
      const permissions = Object.values(x).map((y) => y.name);
      this._permissions = [...permissions];
    });
  }
}
