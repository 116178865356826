import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { AnalyticsService } from '@app/core/services';
import { LetterRecipient } from '@app/features/+letter/models';
import { IMatterCard, IPerson } from '@app/shared/models';
import { GridLoadingOverlayComponent } from '@app/sharedaggrid/components/grid-loading-overlay/grid-loading-overlay.component';
import { GridNoResultsOverlayComponent } from '@app/sharedaggrid/components/grid-no-results-overlay/grid-no-results-overlay.component';
import { RecipientDetailsCellComponent } from '@app/sharedaggrid/components/recipient-details-cell/recipient-details-cell.component';
import { RecipientTypeCellComponent } from '@app/sharedaggrid/components/recipient-type-cell/recipient-type-cell.component';
import { ColDef, GridApi, GridOptions, RowNode } from '@ag-grid-community/core';
import { getObjValue, joinArrayToString } from '../../../../../server/modules/shared/functions/common-util.functions';
import { NoneCardId } from '@app/features/+card/models';
import { AgGridNavigationService } from '@app/shared/services';

@Component({
  selector: 'sc-recipient-list',
  templateUrl: './recipient-list.component.html',
})
export class RecipientListComponent implements OnInit {
  @HostBinding('class')
  classes = 'layout-column lt-container';

  gridOptions: GridOptions;
  private _gridApi: GridApi;

  get isGridRendered(): boolean {
    return !!this._gridApi;
  }

  @Input()
  set matterCards(data: IMatterCard[]) {
    this._matterCards = data;
  }

  get matterCards() {
    return this._matterCards;
  }

  @Input()
  set searchText(data: string) {
    this.doQuickSearch(data);
  }

  @Input()
  analyticsCategory: string;

  @Input()
  set loading(data: boolean) {
    this._loading = data;
    if (this.isGridRendered) {
      if (data) {
        this._gridApi.showLoadingOverlay();
      } else {
        this._gridApi.hideOverlay();
      }
    }
  }

  get loading() {
    return this._loading;
  }

  @Output()
  onRecipientSelected = new EventEmitter<LetterRecipient>();

  @Output()
  onPersonSelected = new EventEmitter<IPerson>();

  private _matterCards: IMatterCard[];
  private _loading: boolean;
  private _recipientIndex: number;

  constructor(private _analyticsSvc: AnalyticsService, private _agGridNavigationService: AgGridNavigationService) {
    this.gridOptions = {} as GridOptions;
  }

  ngOnInit() {
    this.gridOptions = this.getGridOptions();
    this.gridOptions.columnDefs = this.buildColumns();
  }

  getGridOptions(): GridOptions {
    return {
      defaultColDef: {
        sortable: true,
      },
      rowSelection: 'single',
      headerHeight: 37,
      icons: {
        sortAscending: '<span class="ag-icon-down"></span>',
        sortDescending: '<span class="ag-icon-up"></span>',
      },
      suppressContextMenu: true,
      onGridReady: this.onGridReady.bind(this),
      suppressMovableColumns: true,
      suppressHorizontalScroll: true,
      suppressRowDeselection: false,
      loadingOverlayComponent: GridLoadingOverlayComponent,
      noRowsOverlayComponent: GridNoResultsOverlayComponent,
      noRowsOverlayComponentParams: { type: 'recipientList' },
      onRowDataUpdated: (params) => {
        if (this.loading) {
          params.api.showLoadingOverlay();
        }
      },
      onSelectionChanged: (params) => {
        const row = params.api.getSelectedNodes()[0];
        const { data, rowIndex } = row;
        this.redrawRows(rowIndex);
        this.emitSelection(data);
      },
      tabToNextCell: this._agGridNavigationService.tabToNextCell.bind(this._agGridNavigationService),
    } as GridOptions;
  }

  buildColumns(): ColDef[] {
    const padColumn = {
      headerName: '',
      field: '',
      maxWidth: 8,
      minWidth: 8,
    };
    return [
      padColumn,
      {
        headerName: '',
        field: 'selection',
        colId: 'selection',
        headerClass: `is-centered`,
        cellClass: 'is-centered',
        maxWidth: 40,
        checkboxSelection: true,
        hide: true,
      },
      {
        headerName: 'Type',
        field: 'type',
        colId: 'type',
        headerClass: `is-centered`,
        cellClass: 'is-centered',
        maxWidth: 60,
        minWidth: 60,
        cellRenderer: RecipientTypeCellComponent,
        cellRendererParams: {
          getCardType: (row: IMatterCard) => getObjValue(row, 'cardInfo.type', ''),
          getCardPersons: (row: IMatterCard) => row.persons.length,
        },
        getQuickFilterText: (params) => params.data.type,
      },
      {
        headerName: 'Card',
        field: 'persons',
        colId: 'persons',
        cellRenderer: RecipientDetailsCellComponent,
        cellRendererParams: {
          getCardPersons: (row: IMatterCard) => row,
          getCardPersonRole: (row: IMatterCard) =>
            !!row.__name ? joinArrayToString(row.__name.match(/[A-Z][a-z]+/g), ' ') : '',
          getCardPersonDesc: (row: IMatterCard) => (!!row.__description ? row.__description : ''),
          isOrganisationCard: (row: IMatterCard) =>
            !!row.cardInfo && !!row.cardInfo.type ? row.cardInfo.type !== 'People' : false,
          emitRecipient: (params: any, index: number) => this.onSelectedRecipient(params, index),
        },
        getQuickFilterText: (params) =>
          this.toQuickSearchHints(params.data.persons, params.data.__className, params.data.cardShortName),
      },
    ];
  }

  doQuickSearch(searchText: string): void {
    searchText = searchText?.toUpperCase() || '';
    if (this._gridApi) {
      this._gridApi.updateGridOptions({ quickFilterText: searchText });
      this.toggleGridOverlay();
    }
  }

  toQuickSearchHints(persons: IPerson[], className: string, shortName: string): string {
    const names = persons.map((p) => `${p.firstNames} ${p.lastName} ${p.maidenName}`);
    return [...names, className, shortName].join(' ');
  }

  private redrawRows(selectedRowIndex: number): void {
    const rows: RowNode[] = [];
    this._gridApi.forEachNode((row: RowNode, i: number) => {
      if (selectedRowIndex !== i) {
        rows.push(row);
      }
    });
    this._gridApi.redrawRows({ rowNodes: rows });
  }

  private onSelectedRecipient(params: any, recipientIndex: number): void {
    this._recipientIndex = recipientIndex;
    if (params.node.isSelected()) {
      this.emitSelection(params.node.data);
    } else {
      params.node.setSelected(true);
    }
  }

  private emitSelection(data: IMatterCard) {
    if (!data) {
      this.onRecipientSelected.emit(undefined);
    } else {
      const matterCard = data as IMatterCard;

      if (matterCard.persons?.length === 1 && this._recipientIndex === undefined) {
        this._recipientIndex = 0;
      }

      const person = this._recipientIndex >= 0 ? data.persons[this._recipientIndex] : null;
      this.onRecipientSelected.emit({
        tableId: matterCard.subsTableId ? matterCard.subsTableId : matterCard.tableId,
        tableOrder: !!matterCard.__fileOrder ? `${matterCard.__fileOrder}` : null,
        cardId: matterCard.cardId,
        personInstance: this._recipientIndex >= 0 ? (this._recipientIndex + 1).toString() : '',
      });

      this._analyticsSvc.eventTrack({
        category: this.analyticsCategory,
        action: 'Select non-default recipient',
      });

      this.onPersonSelected.emit(person);
      this._recipientIndex = undefined;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this._gridApi) {
      this._gridApi.sizeColumnsToFit();
    }
  }

  onGridReady(params: { api: GridApi }) {
    this._gridApi = params.api;
    this._gridApi.sizeColumnsToFit();
  }

  private toggleGridOverlay(): void {
    if (this.isGridRendered) {
      const visibleRowCount = this._gridApi.getDisplayedRowCount();
      this._gridApi.hideOverlay();
      if (visibleRowCount === 0) {
        this._gridApi.showNoRowsOverlay();
      }
    }
  }
}
