import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IPaymentTypes } from 'app/features/+payments-debtors/models/payment-debtors.models';
import { FormGroup } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { distinctUntilChanged, startWith, take, tap, switchMap, filter as rxjsFilter, takeUntil } from 'rxjs/operators';

import { IPaymentDetailsTypeInit } from '@app/features/+payment-type-details/models';
import { Store, select } from '@ngrx/store';
import { AppApiService } from '@app/core/api';
import * as outflowActions from '@app/features/+payment-type-details/store/actions/outflow-payment-type-details.actions';
import { selectOnCloseResponse } from '@app/features/+payment-type-details/store/selectors/payment-type-details.selector';
import { isEmptyObj, isObjEqual } from '@server/modules/shared/functions/common-util.functions';
import { getPaymentSubTypeIdFunc } from '../../functions/accounting-common.function';
import { PaymentTypeId } from '@app/features/accounting/constants';

@Component({
  selector: 'sc-outflow-payment-type',
  templateUrl: './outflow-payment-type.component.html',
})
export class OutflowPaymentTypeComponent implements OnInit, OnDestroy {
  paymentTypeSub: Subscription;
  paymentType: IPaymentTypes;

  private _paymentTypeForm: FormGroup;
  private _paymentTypeDetailModalSub: Subscription;
  private _cardGuid: string;
  private unsub = new Subject<void>();

  get paymentTypeId(): number {
    const paymentType = this._paymentTypeForm.controls.paymentType.value;
    return this._paymentTypeForm && paymentType && paymentType.PaymentTypeID !== undefined
      ? paymentType.PaymentTypeID
      : undefined;
  }

  get paymentSubTypeId(): number {
    const paymentType = this._paymentTypeForm?.controls.paymentType.value;
    return getPaymentSubTypeIdFunc(paymentType);
  }

  get paymentTypeDetails(): any {
    const paymentTypeDetails = this._paymentTypeForm.controls.paymentTypeDetails.value;
    return this._paymentTypeForm && paymentTypeDetails ? paymentTypeDetails : undefined;
  }

  @Input()
  validationErrors: boolean;

  @Input()
  set paymentTypeForm(form: FormGroup) {
    if (form) {
      this._paymentTypeForm = form;
      this.subscribetoPaymentType();
    }
  }
  get paymentTypeForm() {
    return this._paymentTypeForm;
  }

  @Input()
  paymentTypeList: IPaymentTypes[];

  @Input()
  set cardGuid(value: string) {
    if (!!value) {
      this._cardGuid = value;
      this._store.dispatch(new outflowActions.OutflowPaymentTypeSetPayeeCardId(value));
    }
  }

  get cardGuid(): string {
    return this._cardGuid;
  }

  private _isDisabled: boolean;
  @Input()
  set isDisabled(value: boolean) {
    this._isDisabled = value;

    if (!!this._paymentTypeForm) {
      const paymentTypeCtrl = this._paymentTypeForm.get('paymentType');
      const canDisable = !!paymentTypeCtrl && paymentTypeCtrl.enabled;
      const canEnable = !!paymentTypeCtrl && paymentTypeCtrl.disabled;

      if (!!value && canDisable) {
        paymentTypeCtrl.disable();
      }

      if (!value && canEnable) {
        paymentTypeCtrl.enable();
      }
    }
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  constructor(private _appApiSvc: AppApiService, private _store: Store<any>) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  showPaymentModal() {
    if (!!this._paymentTypeDetailModalSub) {
      this._paymentTypeDetailModalSub.unsubscribe();
    }

    const init: IPaymentDetailsTypeInit = {
      paymentDetails: this._paymentTypeForm.controls.paymentTypeDetails.value,
      paymentType:
        typeof this.paymentType === 'string' ? this._paymentTypeForm.controls.paymentType.value : this.paymentType,
      paymentTypeId: this.paymentTypeId,
      paymentSubTypeId: this.paymentSubTypeId,
    };

    this._store.dispatch(new outflowActions.OutflowPaymentTypeInit(init));
    this._paymentTypeDetailModalSub = this._store
      .pipe(
        take(1),
        tap(() => {
          this._appApiSvc.navigate({
            path: [{ outlets: { selector: ['payment-type-details'] } }],
            extras: { queryParamsHandling: 'merge', skipLocationChange: true },
          });
        }),
        switchMap(() =>
          this._store.pipe(
            select(selectOnCloseResponse),
            tap(() => this._store.dispatch(new outflowActions.OutflowPaymentTypeSetPayeeCardId(this._cardGuid))),
            rxjsFilter((response) => response !== null),
            take(1),
            tap((response) => {
              this._paymentTypeForm.patchValue({
                paymentTypeDetails: response || {},
              });
            })
          )
        ),
        takeUntil(this.unsub)
      )
      .subscribe();
  }

  showPaymentTypeDetailsModal(selected: IPaymentTypes) {
    if (!isEmptyObj(selected)) {
      switch (selected.PaymentTypeID) {
        case PaymentTypeId.Cheque:
        case PaymentTypeId.Cash:
        case PaymentTypeId.CreditCard:
          this.paymentType = selected;
          // we are not to show just memo on initial selection
          break;
        default:
          this.paymentType = selected;
          this.showPaymentModal();
          break;
      }
    }
  }

  subscribetoPaymentType() {
    const paymentTypeControl = this._paymentTypeForm.controls.paymentType;
    this.paymentType = paymentTypeControl.value;
    this.paymentTypeSub = paymentTypeControl.valueChanges
      .pipe(startWith(paymentTypeControl.value), distinctUntilChanged(isObjEqual), takeUntil(this.unsub))
      .subscribe((paymentType: IPaymentTypes) => {
        if (paymentType !== undefined && this._paymentTypeForm.dirty) {
          this.paymentType = paymentType;
          this.showPaymentTypeDetailsModal(paymentType);
        }
      });
  }
}
