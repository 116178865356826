import { Correspondence, CorrespondenceType, DocType, FileType, IDoc, IDocAttachment, IDocsFolder } from '../models';
import { IDocumentRecord } from '@app/features/+preview/models';
import { hasProp, isEmptyObj } from '../../../../server/modules/shared/functions/common-util.functions';

export const commentTitle = (body: string): string => {
  if (!body) {
    return '';
  }

  if (body.includes('<style>')) {
    // Remove <style> and its contents
    body = body.replace(/<style>.*<\/style>/g, '');
  }

  const regexp = `/<[strong][^>]*><[span][^>]*>Edited by\s\w*\s\w*\s\on\s(Sat|Sun|Mon|Tue|Wed|Thu|Fri),\s
            ([0-9]{2}|[0-9])\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Apr|Sep|Oct|Nov|Dec)\s\d{4}\s\at\s
            ([0-9]{2}|[0-9]):([0-9]{2}|[0-9])\s(AM|PM)<\/[span][^>]*><\/[strong][^>]*>/gm;`;

  const noStampString: string = body
    .replace(regexp, '')
    .replace(/<\/p>/gm, '\n')
    .replace(/<\/h[1-6]>/gm, '\n')
    .replace(/<br\s*\/?>/gm, '\n')
    .replace(/<(?:.|\n)*?>/gm, '')
    .trim()
    .replace(/\n+.*$/gm, '')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .trim();

  let title: string = noStampString.length > 0 ? noStampString : '';
  if (title.length === 0) {
    title = title.replace(/<(?:.|\n)*?>/gm, '').trim();
  }

  if (title.length > 60) {
    // Final regexp to remove extra css classes in title
    title = title
      .replace(/body{([^}]+)}/g, '')
      .trim()
      .substring(0, 60)
      .concat('...');
  }

  return title.trim();
};

export const isDocument = (correspondence: IDoc | IDocAttachment): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  return (
    isPdf(correspondence) === true ||
    isWordDocument(correspondence) === true ||
    isExcel(correspondence) === true ||
    isEmail(correspondence) === true ||
    isDraftEmail(correspondence) === true || 
    isTextDocument(correspondence) === true
  );
};

export const isPdf = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  return /(pdf)$/i.test(correspondence.ext);
};

export const isWordDocument = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  return /(doc|docx)$/i.test(correspondence.ext);
};

export const isEmail = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  return /(msg|eml|emlx|oft)$/i.test(correspondence.ext);
};

export const isDraftEmail = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  return /(oft)$/i.test(correspondence.ext);
};

export const isTextDocument = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  return correspondence.ext === 'txt';
};

export const isComment = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  return /(html)$/i.test(correspondence.ext) && [CorrespondenceType.Comment, 'html'].includes((correspondence as IDoc).type);
};

export const isVoiceMemo = (correspondence: IDoc | IDocAttachment): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  return isIDoc(correspondence) === true && (correspondence as IDoc).type === CorrespondenceType.VoiceMemo;
};

export const isImage = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  return /(bmp|gif|jpe?g|png|tiff)$/i.test(correspondence.ext);
};

export const isHtml = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  return /(html|htm)$/i.test(correspondence.ext);
};

export const isExcel = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  return /(xls|xlsx)$/i.test(correspondence.ext);
};

export const isInfotrackOnline = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  const c = correspondence as IDoc;
  return c && isInfotrack(c) && !!c.availableOnline;
};

export const isInfotrack = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  const c = correspondence as IDoc;
  return c && !!c.orderId;
};

export const isInfotrackReady = (correspondence: IDoc | IDocAttachment | IDocumentRecord): boolean => {
  if (isEmptyObj(correspondence) === true) {
    return false;
  }
  const c = correspondence as IDoc;
  return isInfotrack(c) && !!c.ext && isDocument(c);
};

export const isIDoc = (correspondence: IDoc | IDocAttachment): boolean => hasProp(correspondence, 'type');

export const getDocTypeForAnalytics = (correspondence: IDoc | IDocAttachment | IDocumentRecord): string => {
  const type = getDocType(correspondence);
  switch (type) {
    case DocType.Email:
      return 'email';
    case DocType.Excel:
      return 'excel';
    case DocType.Image:
      return 'image';
    case DocType.PDF:
      return 'PDF';
    case DocType.Word:
      return 'word';
    case DocType.Comment:
      return 'comment';

    case DocType.InfotrackOnline:
    case DocType.InfotrackReady:
    case DocType.Infotrack:
      return 'Infotrack link';

    default:
      return 'Document';
  }
};

export const isFolder = <T>(data: Correspondence | T): boolean => {
  const type = data['type'] || null;
  return !!type
    ? type.toString().toLowerCase() === FileType.Folder
    : hasProp(data, 'documents') && hasProp(data, 'folders');
};

export const isFolderEmpty = (data: Correspondence, includeDeleted?: boolean): boolean => {
  if (isFolder(data)) {
    if (!includeDeleted) {
      return (
        (data as IDocsFolder).fullDocuments.filter((d) => d.deleted === false).length +
          (data as IDocsFolder).folders.length ===
        0
      );
    } else {
      return (data as IDocsFolder).documents.length + (data as IDocsFolder).folders.length === 0;
    }
  }
};

export const isPendingPrecedent = (document: IDoc) => !!document.pendingPrecedent;

export const getAllowedExtensionMessage = (allowedDocExt: string[]): string => {
  const bulletedList = allowedDocExt.map(
    (ext) => `<li class="p-1 border-0"><span class="badge badge-info text-lowercase">${ext}</span></li>`
  );
  return `<ul class="d-flex flex-wrap justify-content-center p-0 my-3 border-0 list-unstyled"> ${bulletedList.join(
    ''
  )}</ul>`;
};

export const canOpen = (correspondence: IDoc | IDocAttachment): boolean => {
  if (correspondence.deleted) {
    return false;
  }
  return (
    isDocument(correspondence) === true ||
    isComment(correspondence) === true ||
    isImage(correspondence) === true ||
    isHtml(correspondence) === true ||
    isInfotrackReady(correspondence) === true ||
    isInfotrackOnline(correspondence as IDoc) === true ||
    isTextDocument(correspondence) === true
  );
};

export const getDocType = (doc: IDoc | IDocAttachment | IDocumentRecord): DocType => {
  if (!doc) {
    return DocType.Undefined;
  }

  if (doc.deleted) {
    return DocType.Deleted;
  }

  if (isWordDocument(doc)) {
    return DocType.Word;
  }

  if (isExcel(doc)) {
    return DocType.Excel;
  }

  if (isEmail(doc)) {
    return DocType.Email;
  }

  if (isDraftEmail(doc)) {
    return DocType.DraftEmail;
  }

  if (isTextDocument(doc)) {
    return DocType.TXT; 
  }

  if (isImage(doc)) {
    return DocType.Image;
  }

  if (isPdf(doc)) {
    return DocType.PDF;
  }

  if (isHtml(doc)) {
    return DocType.Html;
  }

  if (isComment(doc)) {
    return DocType.Html;
  }

  if (isInfotrackReady(doc)) {
    return DocType.InfotrackReady;
  }

  if (isInfotrackOnline(doc)) {
    return DocType.InfotrackOnline;
  }

  if (isInfotrack(doc)) {
    return DocType.Infotrack;
  }

  return DocType.Undefined;
};

export const createFileType = (e): string => {
  const lowerCaseInput = e.toLowerCase();
  let fileType = `application/pdf`;
  if (lowerCaseInput === 'pdf' || lowerCaseInput === 'csv') {
    fileType = `application/${lowerCaseInput}`;
  } else if (lowerCaseInput === 'jpeg' || lowerCaseInput === 'jpg' || lowerCaseInput === 'png') {
    fileType = `image/${lowerCaseInput}`;
  } else if (lowerCaseInput === 'txt') {
    fileType = 'text/plain';
  } else if (lowerCaseInput === 'ppt' || lowerCaseInput === 'pot' || lowerCaseInput === 'pps' || lowerCaseInput === 'ppa') {
    fileType = 'application/vnd.ms-powerpoint';
  } else if (lowerCaseInput === 'pptx') {
    fileType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
  } else if (lowerCaseInput === 'doc' || lowerCaseInput === 'dot') {
    fileType = 'application/msword';
  } else if (lowerCaseInput === 'docx') {
    fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  } else if (lowerCaseInput === 'xls' || lowerCaseInput === 'xlt' || lowerCaseInput === 'xla') {
    fileType = 'application/vnd.ms-excel';
  } else if (lowerCaseInput === 'xlsx') {
    fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }

  return fileType;
};
