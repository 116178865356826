import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';

import {
  AnalyticsService,
  BrandService,
  EventBusService,
  LogService,
  PermissionsService,
  PlatformService,
} from '@app/core/services';
import { selectAppDisplayLoading, selectAppLoadingMessage } from '@app/core/store/selectors';
import { Observable, Subscription } from 'rxjs';
import { ESiriusEvents } from '@app/core/models';
import { DialogService } from '@app/shared/services';
import { IDialogOptions } from '@app/shared/models';
import { ToastrService } from 'ngx-toastr';
import * as appActions from '../store/actions/app.action';
import { AnalyticsCategories } from '@app/core/constants/analytics.constant';
import { MainComponent } from '@app/core/pages';
import * as browserTitleActions from '@app/core/store/actions';
import { LocaleInfoMap } from '../constants';
import { omitObjValue } from '../../../../server/modules/shared/functions/common-util.functions';

declare const WalkMeAPI;

@Component({
  selector: 'sc-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  appLoading$: Observable<boolean>;
  appLoadingMessage$: Observable<string>;
  translate: TranslateService;
  isServer = true;
  showDialogEventSub: Subscription = Subscription.EMPTY;
  showToastrEventSub: Subscription = Subscription.EMPTY;

  constructor(
    private _platformSvc: PlatformService,
    private _translateSvc: TranslateService,
    private _store: Store<any>,
    private _log: LogService,
    private _permissionsSvc: PermissionsService,
    private _analyticsSvc: AnalyticsService,
    private _brandSvc: BrandService,
    private _eventBus: EventBusService,
    private _dialogSvc: DialogService,
    private _toastrSvc: ToastrService
  ) {
    this.isServer = this._platformSvc.isServer;
    if (!this.isServer) {
      this._log.init('app.component');
      this._analyticsSvc.startTracking();
      this.translate = _translateSvc;

      // this language will be used as a fallback when a translation isn't found in the current language
      this.translate.setDefaultLang('locale-en-au');

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      const translateId = this.getLocaleTranslateId(this._brandSvc.brandRegion);
      this.translate.use(translateId);
    }

    // disable for mobile devices
    if (!this._platformSvc.isMobileBrowser && this._platformSvc.isBrowser) {
      this._platformSvc.window['walkme_ready'] = (): void => {
        this._store.dispatch(new appActions.SetWalkmeReady({ status: true }));
        WalkMeAPI.changeLanguage(this._brandSvc.walkMeLocale);
      };
    }
  }

  @HostBinding('class.layout-column')
  ngOnInit() {
    if (!this.isServer) {
      this.appLoading$ = this._store.pipe(select(selectAppDisplayLoading));
      this.appLoadingMessage$ = this._store.pipe(select(selectAppLoadingMessage));
      this._permissionsSvc.setupPermissions();
      this._analyticsSvc.sendAnalyticsEvent(AnalyticsCategories.Init, 'Init');
      this.subscribeToEventBus();
    }
  }

  ngOnDestroy() {
    if (!this.isServer) {
      this._analyticsSvc.dispose();
    }
    this.showDialogEventSub.unsubscribe();
    this.showToastrEventSub.unsubscribe();
  }

  public setPrimaryPageTitle($event: Component) {
    if (!($event instanceof MainComponent)) {
      this._store.dispatch(new browserTitleActions.PrimaryPageTitleInit());
    }
  }

  private subscribeToEventBus(): void {
    // showDialog Event Sub
    this.showDialogEventSub = this._eventBus.on(ESiriusEvents.ShowDialog, (value: any) => {
      const dialogType = value.type;
      const dialogOptions: IDialogOptions = omitObjValue(value, ['type']);
      this._dialogSvc.openDialog({ type: dialogType, opts: dialogOptions });
    });

    this.showToastrEventSub = this._eventBus.on(ESiriusEvents.ShowToastr, (value: any) => {
      const { type, message, title } = value;
      this._toastrSvc.show(message, title, {}, type || 'info');
    });
  }

  private getLocaleTranslateId = (region: string): string => {
    const defaultTranslateId = LocaleInfoMap[region]?.translateId;
    if (defaultTranslateId) {
      return defaultTranslateId;
    }

    return 'locale-en-au';
  };
}
