import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { catchError, map as pipeMap } from 'rxjs/operators';

import { StartupService, BrandService, AuthService, LogService } from '@app/core/services';
import { BaseService, TextUtilsService, DocumentAutomationService } from '@app/shared/services';
import { IByLawyersGuide, IPrecedent, ISelectPrecedentPayload } from '../../models';
import { ByLawyersGuides } from './by-lawyers.guides';
import { PrecedentService } from '../precedent/precedent.service';
import { State as PrecedentState } from '../../store';
import * as actions from '../../store/actions';
import { AutomationActionType, FirmDetails } from '@app/shared/models';
import { environment } from '@env/environment';

@Injectable()
export class ByLawyersService extends BaseService {
  constructor(
    private http: HttpClient,
    private _documentAutomationSvc: DocumentAutomationService,
    private _precedentSvc: PrecedentService,
    private _startupSvc: StartupService,
    private _textUtilsSvc: TextUtilsService,
    private _store: Store<PrecedentState>,
    private _brandSvc: BrandService,
    private _authSvc: AuthService,
    private _log: LogService
  ) {
    super();
  }

  get ByLawyersBaseUrl(): string {
    return environment.config.endpoint.bylawyers;
  }

  get PhoenixBaseUrl(): string {
    return environment.config.endpoint.phoenix;
  }

  get FirmType$(): Observable<number> {
    const url: string = this.urlJoin(this.accountingPath, `/api/firmtype`);
    return this.http.get(url).pipe(pipeMap((response: { Type: number }): number => response.Type));
  }

  getByLawyersGuides(matterTypeId: string): IByLawyersGuide[] {
    return ByLawyersGuides.filter((guide) => guide.MatterTypeId === matterTypeId);
  }

  getByLawyersUrl(matterId: string, matterTypeId: string): Observable<string> {
    const guideIds = (this.getByLawyersGuides(matterTypeId) || []).map((guide: IByLawyersGuide) => guide.Id).join(', ');
    return this.FirmType$.pipe(
      pipeMap((firmType: number): string => {
        const urlQuery = `|FRM=${this._startupSvc.userDetails.firmId}|FTY=${firmType}|MID=${matterId}|GID=${guideIds}`;
        const encodedParams = this._textUtilsSvc.b64EncodeUnicode(urlQuery);
        return this.ByLawyersBaseUrl + '/integration/' + encodedParams + '?callback=true';
      })
    );
  }

  getPhoenixIntegratedGuideUrl(
    matterTypeId: string,
    matterState: string
  ): { baseUrl: string; token: string; userId: string; firmId: string } {
    const token = this._authSvc.token;
    const userId = this._authSvc.decodedToken?.userId;
    const firmId = this._startupSvc.userDetails.firmId;
    return {
      baseUrl: `${this.PhoenixBaseUrl}/integratedguide/${matterState}/${matterTypeId}`,
      token,
      userId,
      firmId
    };
  }

  getPhoenixPrecedent(precedentId: string): Observable<IPrecedent | string> {
    return this._precedentSvc.getPrecedent(precedentId).pipe(
      pipeMap((precedent: IPrecedent) => precedent),
      catchError(
        (error: HttpErrorResponse): Observable<string> => {
          this._log.error(error);
          return observableOf('Internal server error!');
        }
      )
    );
  }

  createByLawyersPrecedent(matterTypeId: string, precedentId: string, firmDetails: FirmDetails): Observable<string> {
    const openPrecedent = (precedent: IPrecedent) => {
      const selectMessage: ISelectPrecedentPayload = {
        item: precedent,
        action: { type: AutomationActionType.New },
      };
      this._store.dispatch(new actions.SelectPrecedent(selectMessage));
    };
    return this._precedentSvc.getPrecedent(precedentId).pipe(
      pipeMap((precedent: IPrecedent) => {
        if (this._brandSvc.isTitleX === true || firmDetails.byLawyers === true) {
          openPrecedent(precedent);
        } else {
          this._precedentSvc.showByLawyersWarning(precedent);
        }
        return 'success';
      }),
      catchError(
        (error: HttpErrorResponse): Observable<string> => {
          if (error.status === 404) {
            /// no precedents found
            return this._precedentSvc.getPrecedent(precedentId).pipe(
              pipeMap((precedent: IPrecedent) => {
                if (this._brandSvc.isTitleX === true || firmDetails.byLawyers === true) {
                  openPrecedent(precedent);
                } else {
                  this._precedentSvc.showByLawyersWarning(precedent);
                }
                return 'success';
              }),
              catchError((): string => 'error')
            );
          }
          return observableOf('error');
        }
      )
    );
  }
}
