import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subject, Subscription } from 'rxjs';

import { ESiriusEvents, ITopNavAction } from '@app/core/models';
import { AppApiService } from '@app/core/api';
import { EPermissionType } from '@app/core/constants';
import { IMatterListEntry } from '@app/features/+matter-list/models';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AnalyticsService, BrandService, EventBusService } from '@app/core/services';
import { AnalyticsCategories } from '@app/core/constants/analytics.constant';
import * as currentMatterActions from '@app/features/+matter-details/store/actions';

interface LinkLabel {
  isHeader?: boolean;
  isDivider?: boolean;
  link?: any[];
  label?: string;
  hide?: boolean;
}

@Component({
  selector: 'sc-matter-toolbar',
  templateUrl: './matter-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatterToolbarComponent implements OnInit, OnDestroy {
  private unsub = new Subject<void>();

  @Input()
  toolbarAction: ITopNavAction;

  @Input() isAsideExpanded: boolean;

  private _matter: IMatterListEntry;
  @Input()
  get matter() {
    return this._matter;
  }

  set matter(value: IMatterListEntry) {
    this._matter = value;
    if (!!this._matter) {
      this.setupToolbar();
    }
  }

  private _superDiaryEnabled: boolean;
  @Input()
  get superDiaryEnabled() {
    return this._superDiaryEnabled;
  }

  set superDiaryEnabled(value: boolean) {
    if (!!this._superDiaryEnabled !== !!value) {
      this._superDiaryEnabled = value;
      this.setCalendarLink();
    }
  }

  // public
  officeAccountingDropdown: Array<LinkLabel>;
  trustFundsDropdown: Array<LinkLabel>;
  moreMenuDropdown: Array<LinkLabel>;
  detailsAndCorrenpondence: string[];
  timeAndFees: any[] = [];
  calendar: any[] = [];
  financialSummary: any[] = [];
  permissionEnum: typeof EPermissionType = EPermissionType;
  isSmallScreen$: Observable<boolean>;
  collapseAside$: Observable<boolean>;
  collapseSubscription: Subscription;
  titlexLive: boolean;
  isTitleX: boolean;

  get getAnalyticsCategory(): string {
    return AnalyticsCategories.MatterDetails;
  }

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _eventBusSvc: EventBusService,
    private _brandSvc: BrandService,
    private _store: Store<any>,
    private _appApiService: AppApiService,
    private _cdRef: ChangeDetectorRef,
    private _analyticsService: AnalyticsService
  ) {
    this.titlexLive = environment.production && environment.config.brand.leapcalc === 'titlex';
    this.isTitleX = this._brandSvc.isTitleX;
  }

  ngOnInit() {
    this.isSmallScreen$ = this._breakpointObserver
      .observe(['(max-width: 1380px)'])
      .pipe(map((state: BreakpointState) => state.matches));

    this.collapseAside$ = this._breakpointObserver
      .observe(['(min-width: 900px)'])
      .pipe(map((state: BreakpointState) => state.matches));

    this.collapseSubscription = this.collapseAside$.pipe(takeUntil(this.unsub)).subscribe((i) => this.toggleAside(i));
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  openMatterOptions() {
    this._store.dispatch(new currentMatterActions.GoToMatterOptionsModalPage(null));
  }

  public getAnalyticsLabelAside(isAsideExpanded: boolean): string {
    return this._analyticsService.getTextValueFromBoolean(!isAsideExpanded, 'Expanded/Collapsed');
  }

  private setupToolbar() {
    const matterToolbarId = this._matter.fileNumber === '-1' ? this._matter.matterId : this._matter.fileNumber;
    this.timeAndFees = ['/matters', matterToolbarId, 'time-fee'];
    this.financialSummary = ['/matters', matterToolbarId, 'financial-summary'];
    this.setCalendarLink();
    this.detailsAndCorrenpondence = ['/matters', matterToolbarId];

    this.officeAccountingDropdown = [
      {
        link: ['/matters', matterToolbarId, 'payments-debtors'],
        label: 'PaymentDebtors.Label',
      },
      {
        link: ['/matters', matterToolbarId, 'credit-ledger'],
        label: 'Credit.Menu.CreditLedger',
      },
      {
        link: ['/matters', matterToolbarId, 'anticipated-payments'],
        label: 'AnticipatedPayment.Label',
        hide: this.titlexLive,
      },
      {
        link: ['/matters', matterToolbarId, 'cost-recoveries'],
        label: 'CostRecovery.Label',
      },
    ];

    this.trustFundsDropdown = [
      {
        link: ['/matters', matterToolbarId, 'trust-fund'],
        label: 'Trust.General.Ledger.Label',
      },
      {
        link: ['/matters', matterToolbarId, 'protected-trust-funds'],
        label: 'Trust.ProtectedFund.Ledger.Label.Full',
        hide: this.isTitleX || this.titlexLive,
      },
      {
        link: ['/matters', matterToolbarId, 'trust-investment-ledger'],
        label: 'Trust.Investment.Ledger.Label',
        hide: this.titlexLive,
      },
      {
        link: ['/matters', matterToolbarId, 'power-money'],
        label: 'Trust.PowerMoney.Ledger.Label',
        hide: !this._brandSvc.isPowerMoneyEnabled,
      },
    ];

    this.moreMenuDropdown = [
      {
        isHeader: true,
        label: 'Accounting.Office.Label',
      },
      {
        link: ['/matters', matterToolbarId, 'payments-debtors'],
        label: 'PaymentDebtors.Label',
      },
      {
        link: ['/matters', matterToolbarId, 'credit-ledger'],
        label: 'Credit.Menu.CreditLedger',
      },
      {
        link: ['/matters', matterToolbarId, 'anticipated-payments'],
        label: 'AnticipatedPayment.Label',
        hide: this.titlexLive,
      },
      {
        link: ['/matters', matterToolbarId, 'cost-recoveries'],
        label: 'CostRecovery.Label',
      },
      {
        isDivider: true,
      },
      {
        isHeader: true,
        label: 'Trust.General.Fund.MenuGroup',
      },
      {
        link: ['/matters', matterToolbarId, 'trust-fund'],
        label: 'Trust.General.Ledger.Label',
      },
      {
        link: ['/matters', matterToolbarId, 'protected-trust-funds'],
        label: 'Trust.ProtectedFund.Ledger.Label.Full',
        hide: this.titlexLive || this.isTitleX,
      },
      {
        link: ['/matters', matterToolbarId, 'trust-investment-ledger'],
        label: 'Trust.Investment.Ledger.Label',
        hide: this.titlexLive,
      },
      {
        link: ['/matters', matterToolbarId, 'power-money'],
        label: 'Trust.PowerMoney.Ledger.Label',
        hide: !this._brandSvc.isPowerMoneyEnabled,
      },
    ];
  }

  toggleAside(open: boolean): void {
    this._appApiService.toggleAside(open);
  }

  handleClickOnToolbarAction(action: { actionName: string; event: ESiriusEvents }) {
    this._eventBusSvc.emit({ name: action.event });
  }

  private setCalendarLink(): void {
    if (!!this._matter) {
      const matterToolbarId = this._matter.fileNumber === '-1' ? this._matter.matterId : this._matter.fileNumber;
      this.calendar = ['/matters', matterToolbarId, this._superDiaryEnabled ? 'schedule' : 'calendar'];
    }
  }
}
