export interface AddinVisibilityManagable {
  MatterType: string[];
  State: string[];
  BillingMode: number[];
}

export interface AddinOpenable {
  Browser: AddinBrowser;
  MessageBus?: boolean;
}

export interface AddinRecord {
  Title: string;
  ExternalURL: string;
  ExternalJSON: string;
}

export interface AddinList {
  APIList: { APIAddIn: Addin }[];

  // too insane, will revisit
  NewMatterAPIList: {
    MatterAPI: {
      Name: string;
      WindowTitle: string;
      MenuItem: string;
      URL: string;
    };
  }[];
}

export enum AddinActionType {
  Button = 'button',
  MenuItem = 'menu-item',
  DropDown = 'dropdown',
  SubItem = 'sub-item',
  ContextHeader = 'context-header',
  Context = 'context',
}

export enum AddinTriggerApp {
  Outlook = 'msOutlook',
  Word = 'msWord',
}

export enum AddinWindowType {
  System = 'system',
  Hidden = 'hidden',
  Web = 'web',
}

export interface AddinItem {
  functionGroupId: string;
  actionType: AddinActionType;
  name: string;
  label: string;
  description: string;
  windowType: AddinWindowType;
  triggeredFromApplication: AddinTriggerApp[];
  hidden?: boolean;
  visibility: {
    // LEAP app field with value 218 = Legal Aid
    billingModes: number;
    matterTypes: string[];
    states: string[];
    entityType: string[];
  };
  icon: {
    url: string;
  };
  // nested structure, only applicable for actionType `ContextHeader` and `DropDown`
  items: AddinItem[];
  window: {
    title: string;
    width: number;
    height: number;
    useNativeLoading: boolean;
  };
  url: string;
  // if true, then need to prepare appSession and pass appSessionId to the URL
  messageBus: boolean;
  // if true, then needs to make sure that the firm has linked to Microsoft 365 or Google, and the context user has been mapped
  externalEmailIntegrationReq: boolean;
}

export interface AddinItemWithAppInform {
  appId: string;
  appName: string;
  appGroupId: string;
  isAppEnabled: true;
  trustedApp: true;
  id: string;
  actionType: string;
  name: string;
  label: string;
  description: string;
  windowType: string;
  triggeredFromApplications: Array<string>;
  window: {
    title: string;
    width: number;
    height: number;
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
    hideControls: boolean;
    closeHandler: string;
    useNativeLoading: boolean;
  };
  url: string;
  messageBus: boolean;
  externalEmailIntegrationReq: boolean;
  width: number;
}

export interface Addin {
  Name: string;
  Description: string;
  Icon: string;
  URL: string;
  FirmWide: boolean;
  AlwaysShow: boolean;
  GroupLabel: string;
  Button: AddinButton[];
  Menu: AddinMenu[];
  ContextMenu: AddinContextMenu[];
  Browser?: AddinBrowser;
  MessageBus?: boolean;
  TimeEntryConfig?: any;
}

export interface AddinBrowser {
  Type: string;
  Width: number;
  Height: number;
  MinWidth: number;
  MinHeight: number;
  HideControls: boolean;
  CloseHandler?: any;
}

export interface AddinButton extends AddinVisibilityManagable, AddinOpenable {
  Name: string;
  Width: number;
  Height: number;
  Label: string;
  Icon: string;
  URL: string;
  PageNumber: number[];
  DropdownMenu: AddinDropdownMenu[];
}

export interface AddinDropdownMenu extends AddinOpenable {
  Title: string;
  URL: string;
}

export interface AddinContextMenu extends AddinVisibilityManagable, AddinOpenable {
  Title: string;
  URL: string;
  Context: string;
  EntityType: string;
}

export interface AddinMenu extends AddinVisibilityManagable, AddinOpenable {
  Title: string;
  URL: string;
  Location: string;
}

export interface AddinRecordMeta {
  OpenType: string;
  ShowControls: boolean;
  Width: number;
  Height: number;
  MinWidth: number;
  MinHeight: number;
  WindowTitle?: string;
}

export interface MatterAddinPostPayload {
  [key: string]: any;

  authtoken: string;
  matterContext: {
    matterId: string;
    matterTypeId: string;
    transactions?: any[];
  };
}

export interface EnabledAddin {
  addInName: string;
}

export interface IContextMenuAction {
  html: (item: any) => AddinDropdownMenu['Title'];
  click: (item: any) => void;
  enabled: (item: any) => boolean;
  visible: (item: any) => boolean;
  divider?: boolean;
}

interface LeapApp {
  id: string;
  groupId: string;
  name: string;
  version: string;
  clientid: string;
  trustedApp: boolean;
}

export interface GlobalLeapApp extends LeapApp {
  menus: LeapAppMenu[];
}

export interface ViewLeapApp extends LeapApp {
  view: LeapAppView;
}

export enum LeapAppType {
  Global = 'global',
  MatterView = 'matter-view',
  PersonView = 'person-view',
  CardView = 'card-view',
  PeopleCardView = 'people-card-view',
}

export enum ViewLeapAppLocationId {
  DetailsCorrespondence = 'c1437dfc-8f72-4ec9-b956-e7deb9f52c7a',
  CalendarTasks = '780c12a2-eecb-439d-9dad-303ad26ffc9b',
  FinancialSummary = 'f6e67400-a9fc-4432-83a6-23700e0f283e',
  TimeFeeList = 'd7d24039-4f3a-46b8-9fb2-72ec5973592e',
  PaymentsDebtors = '751fbdcd-bcd3-4171-9955-db19c5e70d9f',
  AnticipatedPaymentList = '003b8d6a-1e92-4e25-aff2-78f344fb82a2',
  CreditLedger = '103b8d6a-1e92-4e25-aff2-78f344fb82a2',
  CostRecoveryList = 'fa156449-8507-4055-9e91-528ec4cb7f14',
  TrustLedger = '7238205e-e51e-4e9d-8372-a113d4f9d6e9',
  ProtectedTrustFundList = '73c7d77f-ca39-455e-8fbc-c9529b67dcae',
  TrustInvestmentList = '39114473-6269-42d6-acd4-1fb42a4c715f',
  PeopleBasic = '8f26b189-85ec-4e2d-93a2-fa530630739b',
  PeopleOther = 'da88088a-4b58-4835-94e4-f5618a49a1ef',
  PeopleCardList = 'e7a09711-468b-4289-923b-f3b188d9baa4',
  PeopleMatterList = '17ddc6d7-fd24-4f31-894c-810c8cfde6c5',
  CardDetails = '4f53c470-2de4-4e12-bdbf-3469fb344003',
  CardAddress = '3f7cc840-eec0-4e37-8379-58956ee8ed96',
  CardPeople = '1c7fb81f-d221-456e-af76-8743b5c75573',
  CardMatter = 'be1d4463-1154-46d1-8ccf-1976c057fb57',
  CardOther = '8bf48a25-81bf-3740-ac4f-445285bff4db'
}

export enum GlobalLeapAppLocationId {
  GlobalHomeMatters = '669375ec-54e8-4ff1-ba78-c0eee124d048',
  GlobalHomeCards = '8d77d4b7-f7b5-42fd-bced-7a165792bbab',
  GlobalHomeLawConnect = '36ceb667-d5b7-4be7-9789-506f941b66e0',
  GlobalAccountingOffice = '17acb8b9-a7da-4eb4-b465-3c4640110120',
  GlobalAccountingTrust = '4bc657ca-d5cb-4fa1-a2d4-01b4603fce42',
  GlobalAccountingGeneral = 'e902b7f6-a671-48af-b14c-48bd574cbb6f',
  GlobalOtherTools = '554f4128-f68f-4a79-b506-5a32450ad440',
  GlobalOtherLegalRates = '47b82551-ee92-4ff0-a119-0439f7f6e029'
}

export enum LeapAppGridId {
  Details = '80832a63-eb61-4f7d-9dd4-61f5eca17d03',
  Correspondence = '28e70bd1-8f63-4838-9960-04344467e9a5',
  CriticalDates = '61d243b7-e2a7-4c44-ac4b-fb995025d84f',
  TimeFeeList = '2b8d1a5b-590f-4622-ad80-24dfa864429f',
  PaymentsDebtors = 'a179f07b-8507-446c-804d-a2ed8576a5d3',
  AnticipatedPaymentList = '70654505-344c-4bff-ba67-98abbfbda4be',
  CreditLedger = '05b05cae-4750-417d-9fca-8c838fbe0cb6',
  CostRecoveryList = 'fa84af47-accf-4f89-8d72-db96516f1946',
  TrustLedger = '9de805ba-fea9-498b-9d32-cdd7de9da4df',
  ProtectedTrustFundListTransactions = '3bd0511b-55be-4c66-a21e-9b57a2c5fb50',
  ProtectedTrustFundListAccounts = '4352106f-e475-46b2-8058-d615e970f939',
  TrustInvestmentList = '60d549b4-73a1-43bd-b567-c4234e815e77',
}

export interface LeapAppMenu {
  id: GlobalLeapAppLocationId;
  name: string;
  items: AddinItem[];
}

export interface LeapAppView {
  id: string;
  name: string;
  buttons: AddinItem[];
  grids: LeapAppViewGrid[];
  groupLabel: string;
}

export interface LeapAppViewGrid {
  gridId: LeapAppGridId;
  click: AddinItem[];
  context: AddinItem[];
}
